import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class adListServer {
  @Inject(HttpService) private http!: HttpService;
  //下载
  public downLoadAll(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/pcImport/exportTemplate', param, filename);
  }
  //导出
  public exportReport(param: Dict<any>, filename: any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/reportFrom/exportReport', param, filename);
  }

  //列表
  public getAdList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/inventedOrganizeStructure/inventedOrganizeStructureList', param);
  }

  //france下拉框
  public franchise(): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcDropDownList/franchise');
  }
}
